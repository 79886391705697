@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner {
    display: block;
    height: 50px;
    width: 50px;
    margin: auto;
}

.spinner:before {
    -webkit-animation: spin .5s infinite linear;
    animation: spin .5s infinite linear;
    border-radius: 100%;
    -webkit-box-shadow: inset -2px 0 0 0px #389cff;
    box-shadow: inset -2px 0 0 0px #389cff;
    content: '';
    height: 50px;
    position: absolute;
    width: 50px; 
  }

.table_clickabled .ant-table-row {
    cursor: pointer;
}

.color_swatch {
    padding: 2px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    display: inline-block;
    cursor: pointer;
}

.color_color {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    background: #fff;
}

.color_popover {
    position: absolute;
    z-index: 2;
    top: 30px;
    left: -76px;
}

.color_cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.color_popover>.block-picker> :nth-child(3)> :nth-child(2) {
    display: none;
}

.ant-input-suffix {
    position: relative;
}

@media (max-width: 700px) {
    .color_popover {
        left: -50vw;
    }
}

.table_deals_name {
    display: flex;
}

.table_deals_name>.name {}

.table_deals_name>.color {
    padding: 2px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 10%);
    display: flex;
    margin: auto 10px auto 0;
    cursor: pointer;
    height: fit-content;
}

.table_deals_name>.color>span {
    display: flex;
    margin: auto;
    width: 14px;
    height: 14px;
    border-radius: 4px;
    background: #fff;
}

.deals_tabs {
    max-width: 100%;
}

.deals_tabs_content {
    max-width: 600px;
    margin: auto;
}

.deals_tabs > .ant-tabs-nav::before {
    content: none;
}

.deals_tabs .ant-tabs-nav-list > .ant-tabs-tab {
    border-radius: 20px !important;
}

.ant-btn-dashed[disabled], .ant-btn-dashed[disabled]:hover, .ant-btn-dashed[disabled]:focus, .ant-btn-dashed[disabled]:active {
    background: linear-gradient(180deg, rgb(216, 132, 132) 0%, rgb(163, 62, 62) 100%) !important;
    border-color: rgb(0, 0, 0);
}

.transactionInfo .ant-drawer-content-wrapper {
    width: 100% !important;
    max-width: 700px;
}

.transactionInfo .ant-drawer-content {
    background-color: #f5f7f7;
}

.transactionInfo .ant-drawer-body {
    padding: 10px 28px;
    background: url(../src/img/bg_drawer.png) no-repeat top,#F6F7F7;
    background-size: cover;
    background-attachment: fixed;
    background-position-y: bottom;
}

.transactionInfo .ant-drawer-header {
    background-color: #065FB5;
    color: #fff;   
}

.transactionInfo .ant-drawer-title {
    color: #fff;   
}

.transactionInfo .ant-drawer-header .anticon svg {
    color: #fff;
}

.money_copy {
    display: flex;
    align-items: center;
    justify-content: center;
}

.money_copy .ant-typography-copy {
    height: 24px;
    width: 24px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.money_copy .ant-typography-copy svg {
    fill: #688FA1;
}

.money_copy .ant-typography-copy:hover {
    background-color: #d5e1e1 !important;
    border-radius: 4px;
    transition-duration: .2s;
}

.icon_default svg {
    fill: #9EB5C4
}

.margin_top {
    margin-top: 20px;
}

.p_icon span {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: #5c6a70;
    margin-left: 10px;
}

.divider_text {
    padding: 5px 10px;
    background: #9ec0e3;
    border-radius: 100px;
    color: #ffffff;
}

.contacts_list_card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 400px;
    overflow: auto;
    margin-bottom: 20px;
}

.contacts_list_item {
    width: 100%;
    margin-top: 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #333;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    position: relative;
}

.contacts_list_item_phone {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
}

.contacts_list_item_name {
    cursor: pointer;
    width: fit-content;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #333;
    align-items: center;
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contacts_list_item_unlink {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 20px;
}

.log_timeline .ant-timeline-item-head {
    background: none;
}

.log_timeline .ant-timeline-item {
    padding-bottom: 30px;
}

.ant-drawer-title {
    display: flex;
}

.global_stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.global_stats_item {
    flex: 1 0 auto;
    height: 85px;
    background: #e1e8e8;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin: 0 5px 10px 5px;
}

@media (max-width: 600px) {
    .global_stats {
        flex-direction: column;
    }
}

.global_stats_item .count {
    display: flex;
    align-items: baseline;
    font-size: 20px;
    line-height: 20px;
    color: #5c6a70;
    font-weight: 700;
}

.global_stats_item .title {
    font-size: 13px;
    line-height: 17px;
    color: #5c6a70;
}

.global_deals .ant-collapse-header {
    display: flex;
    align-items: center;
}

.global_deals_item_list {
    display: flex;
    flex-wrap: wrap;
}

.global_deals_item_list_item {
    margin-right: 40px;
}

.global_deals_item_list_item .count {
    display: flex;
    align-items: flex-end;
    margin-bottom: 6px;
}

.global_deals_item_list_item .count_count {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    margin-right: 5px;
}

.global_deals_item_list_item .count_title {
    font-size: 13px;
    line-height: 16px;
    color: #5c6a70;
}

.global_deals_item_list_item .title {
    font-size: 15px;
    line-height: 20px;
}

.date_deals, .date_deals * {
    font-family: 'Montserrat-Semibold' !important;
}